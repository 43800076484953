// @ts-nocheck
import React, { FC, useEffect, useState } from "react";
import { Icon, IconType } from "../Icon";

export enum ButtonType {
	Primary = 1,
	Secondary = 2,
	Ghost = 4,
	Small = 8,
	Large = 16,
	TextNaked = 32,
	IconNaked = 64,
	Icon = 128,
	BigIcon = 256,
}

export enum ButtonState {
	Enabled,
	Disabled,
}

export type ButtonTypes = "primary" | "secondary" | "small" | "large" | "textNaked" | "iconNaked" | "icon" | "bigIcon"
export type OverwriteColors = "danger" | "warning" | "success" | 'light';

export interface ButtonProps {
	id?: string
	icon?: string
	text?: string
	onClick?: any
	// type?: ButtonType | ButtonType.Primary
	type?: ButtonTypes | ButtonType | ButtonType.Primary
	outlined?: boolean
	state?: ButtonState
	ignoreValidation?: boolean //Only for form validation
	shouldDisplay?: any //Only for action buttons in Advanced Table Action Column
	properties?: { [key: string]: any }
	url?: string
	newWindow?: boolean
	padded?: boolean
	badgeNumber?: number
	trigger?: any
	isBusy?: boolean
	iconType?: IconType
	title?: string
	overwriteColor?: OverwriteColors
	recaptchaKey?: string | null
	block?: boolean
	textBefore?: string
	isFormSubmitButton?: boolean
	customBackgroundColor?: string
	customTextColor?: string
	customBorderColor?: string
	customIconColor?: string
	elevation?: number
	onMouseEnter?: () => void
	onMouseLeave?: () => void
	customAriaLabel?: string
	ariaLabelledBy?: string
}

const Button: FC<ButtonProps> = (props) => {
	const [ariaText, setAriaText] = useState<string>('')


	useEffect(() => {
		setAriaText(props.customAriaLabel || props.title || props.text || props.icon || '');
	}, [props.title, props.customAriaLabel])

	const methods = {
		getCustomButtonStyles: ():object => {
			let styles:any = {};

			if(props.customBackgroundColor) styles.backgroundColor = props.customBackgroundColor;
			if(props.customTextColor) styles.color = props.customTextColor;
			if(props.customBorderColor) styles.borderColor = props.customBorderColor;
			
			return styles;
		},
		getClasses(): string {
			var classes: string[] = [];
			if (props.type == ButtonType.Primary) classes.push("btn-primary");
			if (props.type == (ButtonType.Primary | ButtonType.Ghost)) classes.push("btn-outline-primary");
			if (props.type == ButtonType.Secondary) classes.push("btn-secondary");
			if (props.type == (ButtonType.Secondary | ButtonType.Ghost)) classes.push("btn-outline-secondary");
			
			if (typeof props.type === 'string' && !props.outlined && (props.type === 'primary' || props.type === 'secondary')) classes.push(`btn-${props.type}`);
			else if (typeof props.type === 'string' && props.outlined && (props.type === 'primary' || props.type === 'secondary')) classes.push(`btn-outline-${props.type}`)

			if (props.type == ButtonType.Small || props.type === "small") classes.push("btn-sm");
			if (props.type == ButtonType.Large || props.type === "large") classes.push("btn-lg");
			if (props.type == ButtonType.TextNaked || props.type === "textNaked") classes.push("btn-link-basic");
			if (props.type == ButtonType.IconNaked || props.type === "iconNaked") classes.push("btn-icon-basic");
			if (props.type == ButtonType.BigIcon || props.type === "bigIcon") classes.push("btn-big-icon");

			if (props.state === ButtonState.Disabled) {
				classes.push("disabled");
			}

			if (!props.padded) classes.push("no-padding");

			if (props.isBusy) classes.push("busy-indicator");

			if (props.overwriteColor) classes.push(`btn-${props.overwriteColor}`);

			if (props.recaptchaKey) classes.push("btn-recaptcha");

			if (props.block) classes.push("btn-block");
			
			if(props.elevation) classes.push(`elevation-${props.elevation}`);

			return classes.join(" ");
		},
		getAriaDisabledState(): boolean | undefined {
			if (props.state === ButtonState.Disabled) return true;
			return undefined;
		},
		loadRecaptcha() {
			return new Promise((resolve) => {
				const isScriptExist = document.getElementById("recaptcha-key");
				if (!isScriptExist) {
					var script = document.createElement("script");
					script.type = "text/javascript";
					script.src = `https://www.google.com/recaptcha/api.js?render=${props.recaptchaKey}`;
					script.id = "recaptcha-key";
					script.onload = function () {
						resolve(true);
					};
					document.body.appendChild(script);
				} else {
					resolve(true);
				}
			});
		},
		callClick(res?: any) {
			if (props.recaptchaKey) {
				//@ts-ignore
				window.grecaptcha?.ready(() => {
					//@ts-ignore
					window.grecaptcha?.execute(props.recaptchaKey, { action: "submit" }).then((token) => {
						props.onClick(token);
					});
				});
			} else {
				props.onClick(res);
			}
		},
	};

	useEffect(() => {
		if (props.recaptchaKey) {
			methods.loadRecaptcha();
		}
		// eslint-disable-next-line
	}, []);

	let tabindex = props.state === ButtonState.Disabled ? { tabIndex: -1 } : { tabIndex: 0 };
	var tooltipText: string | undefined;

	if (props.type === ButtonType.IconNaked || props.type === ButtonType.Icon || props.type === "iconNaked" || props.type === "icon") {
		tooltipText = props.text;
	} else {
		tooltipText = "";
	}

	return (
		// This span should perhaps only be present if there is a different theme
		<span
			className={props.theme}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
            {(!props.url)? (
                <button
                    ref={methods.loadButtonTooltip}
                    id={props.id || props.text || "button"}
					style={ methods.getCustomButtonStyles() }
                    type={props.isFormSubmitButton ? "submit" : "button"}
					disabled={props.state === ButtonState.Disabled}
                    className={`btn ${methods.getClasses()}`}
                    aria-disabled={methods.getAriaDisabledState()}
                    aria-busy={props.isBusy}
                    onClick={(e: any) => {

						if(!props.isFormSubmitButton){
							e.preventDefault();
						}

                        if (!props.isBusy && props.state === ButtonState.Enabled) {
                            if (!props.trigger) return methods.callClick();

                            if (props.ignoreValidation) return methods.callClick();

                            props.trigger &&
                                props.trigger().then((result: any) => {
                                    methods.callClick(result);
                                });
                        }
						else {
							return e.preventDefault();
						}
                    }}
                    onKeyPress={(e: any) => methods.callClick()}
                    title={tooltipText}
                    {...tabindex}
                    {...props.properties}
                    aria-label={ariaText}
					{...props.ariaLabelledBy && {['aria-labelledby']: `${props.ariaLabelledBy}`}}
                >        
                    {!(props.type == ButtonType.IconNaked || props.type === "iconNaked") && !(props.type == ButtonType.Icon || props.type === "icon") && props.textBefore}
                    {!!props.icon && <Icon color={props.customIconColor} hideAriaLabel={true} icon={props.icon} type={props.iconType || "light"} padded={true} tabIndex={-1}/>}
                    {!(props.type === ButtonType.IconNaked || props.type === "iconNaked") && !(props.type === ButtonType.Icon || props.type === "icon") && (
						ariaText ? (
							<span aria-hidden="true">{props.text}</span>
						) : (
							props.text
						)
					)}

                    {props.badgeNumber !== undefined && props.badgeNumber > 0 && <span className={`badge badge-pill badge-warning`}>{props.badgeNumber}</span>}
                    {props.isBusy && (
                        <div className="loader">
                            <div className="pulsate-loading"></div>
                        </div>
                    )}
                </button> 
				) : (
					<a 	role="button"
						ref={methods.loadAnchorTooltip}
						id={props.id || props.text || "button"}
						style={ methods.getCustomButtonStyles() }
						className={`btn ${methods.getClasses()}`}
						aria-disabled={methods.getAriaDisabledState()}
						aria-busy={props.isBusy}
						{...props.ariaLabelledBy && {['aria-labelledby']: `${props.ariaLabelledBy}`}}
						onClick={(e: any) => {
							if (!props.isBusy && props.state === ButtonState.Enabled) {
								if (!props.trigger) return methods.callClick();

								if (props.ignoreValidation) return methods.callClick();

								props.trigger &&
									props.trigger().then((result: any) => {
										methods.callClick(result);
									});
							}
						}}
						onKeyPress={(e: any) => methods.callClick()}
						title={tooltipText}
						{...tabindex}
						{...props.properties}
						href={props.url}
						target={props.newWindow ? '_blank' : '_self'}
						aria-label={ariaText}
					>
						{!(props.type === ButtonType.IconNaked || props.type === "iconNaked") && !(props.type === ButtonType.Icon || props.type === "icon") && props.textBefore}
						{!!props.icon && <Icon color={props.customIconColor} hideAriaLabel={true} icon={props.icon} type={props.iconType || "light"} padded={true} tabIndex={-1}/>}

						{!(props.type === ButtonType.IconNaked || props.type === "iconNaked") && !(props.type === ButtonType.Icon || props.type === "icon") && (
							ariaText ? (
								<span aria-hidden="true">{props.text}</span>
							) : (
								props.text
							)
						)}

						{props.badgeNumber !== undefined && props.badgeNumber > 0 && <span className={`badge badge-pill badge-warning`}>{props.badgeNumber}</span>}
						{props.isBusy && (
							<div className="loader">
								<div className="pulsate-loading"></div>
							</div>
						)}
					</a>
				)}

			
		</span>
	);
};

Button.defaultProps = {
	icon: "",
	text: "",
	onClick: () => void 0,
	type: 'primary',
	outlined: false,
	state: ButtonState.Enabled,
	padded: true,
    isFormSubmitButton: false,
	elevation: "0",
	id: undefined,
	ignoreValidation: undefined,
	shouldDisplay: undefined,
	properties: undefined,
	url: undefined,
	badgeNumber: undefined,
	trigger: undefined,
	theme: undefined,
	isBusy: undefined,
	iconType: undefined,
	title: undefined,
	overwriteColor: undefined,
	recaptchaKey: undefined,
	block: undefined,
	textBefore: undefined,
	customBackgroundColor: undefined,
	customTextColor: undefined,
	customBorderColor: undefined,
	customIconColor: undefined,
	onMouseEnter: () => void 0,
	onMouseLeave: () => void 0,
	customAriaLabel: undefined,
	ariaLabelledBy: undefined,
};

export default Button;
